import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { Bank } from "@/types/bank";
import { profileModule } from "@store/namespaces";
import { ProfileGetters } from "@store/modules/profile/types";
import {
  ISSUE_CARD_FORM_BODY_CLASSNAME,
  ISSUE_CARD_CONFIRM_BODY_CLASSNAME,
} from "@config/base";
import { CardType } from "@/types/card";

@Component({
  inheritAttrs: false,
  components: {
    CardInfo: () => import("../../../common/components/CardInfo/CardInfo.vue"),
    IssuePrepaidCardForm: () =>
      import(
        "../../../common/components/IssuePrepaidCardForm/IssuePrepaidCardForm.vue"
      ),
    IssuePostpaidCardForm: () =>
      import(
        "../../../common/components/IssuePostpaidCardForm/IssuePostpaidCardForm.vue"
      ),
  },
})
export default class IssueCard extends Vue {
  @profileModule.Getter("profileFullName")
  private readonly profileFullNameGetter!: ProfileGetters["profileFullName"];

  @Prop({ type: Object, required: true })
  private readonly selectedBank!: Bank;
  @Prop({ type: String, required: true })
  private readonly cardType!: CardType;

  private confirmed = false;

  private get formComponentName() {
    return this.cardType === CardType.POSTPAID
      ? "IssuePostpaidCardForm"
      : "IssuePrepaidCardForm";
  }

  @Watch("confirmed", {
    immediate: true,
  })
  private onChangeConfirm() {
    this.scrollToTop();
    this.toggleMode(this.confirmed ? "confirm" : "form");
  }

  private async onConfirm({ validate }: { validate: () => Promise<boolean> }) {
    const confirmed = await validate();

    this.toggleConfirm(confirmed);
  }

  private scrollToTop() {
    window.scrollTo(0, 0);
  }

  private toggleMode(mode: "confirm" | "form") {
    document.body.classList.toggle(
      ISSUE_CARD_FORM_BODY_CLASSNAME,
      mode === "form"
    );
    document.body.classList.toggle(
      ISSUE_CARD_CONFIRM_BODY_CLASSNAME,
      mode === "confirm"
    );
    this.$emit("change:mode", mode);
  }

  private toggleConfirm(value = !this.confirmed) {
    this.confirmed = value;
  }

  private onIssued() {
    this.scrollToTop();
    this.toggleMode("form");
  }

  private beforeDestroy() {
    document.body.classList.remove(
      ISSUE_CARD_FORM_BODY_CLASSNAME,
      ISSUE_CARD_CONFIRM_BODY_CLASSNAME
    );
  }
}
